// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n.ce-textimage_Skqii{\n  position:relative;\n  display:grid;\n  grid-template-columns:repeat(1, minmax(0, 1fr))\n}\n@media (min-width: 1024px){\n.ce-textimage_Skqii{\n    grid-template-columns:repeat(2, minmax(0, 1fr))\n}\n}\n@media (min-width: 1024px){\n.ce-textimage_Skqii .assets_L2c5D.left_-UO5r{\n        order:1\n}\n.ce-textimage_Skqii .assets_L2c5D.right_k2vQZ{\n        order:3\n}\n}\n.ce-textimage_Skqii .item_BLcYl:not(:last-child){\n    margin-bottom:2.5rem\n}\n.ce-textimage_Skqii .textblock_wcYje{\n    order:2;\n    display:flex;\n    flex-direction:column;\n    padding:2rem;\n    padding-bottom:0px\n}\n@media (min-width: 768px){\n.ce-textimage_Skqii .textblock_wcYje{\n      padding:3rem\n}\n}\n@media (min-width: 1536px){\n.ce-textimage_Skqii .textblock_wcYje{\n      padding-right:10rem;\n\n      padding-left:5rem\n}\n}\n.ce-textimage_Skqii .subheader_4FjL1{\n    margin-bottom:2rem\n}\n.ce-textimage_Skqii .bodytext_J8-8J:not(:last-of-type){\n      margin-bottom:1.5rem\n}\n.ce-textimage_Skqii .header_S5lSW:not(:last-of-type){\n      margin-bottom:0.5rem\n}\n.ce-textimage_Skqii .button_3BRJF{\n    margin-top:auto\n}\n.ce-textimage_Skqii ol, .ce-textimage_Skqii ul{\n    padding-left:1rem\n}\n.ce-textimage_Skqii ol{\n    list-style-type:decimal\n}\n.ce-textimage_Skqii ul{\n    list-style-type:disc\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ce-textimage": "ce-textimage_Skqii",
	"assets": "assets_L2c5D",
	"left": "left_-UO5r",
	"right": "right_k2vQZ",
	"item": "item_BLcYl",
	"textblock": "textblock_wcYje",
	"subheader": "subheader_4FjL1",
	"bodytext": "bodytext_J8-8J",
	"header": "header_S5lSW",
	"button": "button_3BRJF"
};
module.exports = ___CSS_LOADER_EXPORT___;
