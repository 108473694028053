// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n.ce-image_orbFk{\n  position:relative;\n  max-height:100vh;\n  overflow:hidden;\n}\n.ce-image_orbFk img{\n    height:100%;\n    width:100%;\n    -o-object-fit:cover;\n       object-fit:cover\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ce-image": "ce-image_orbFk"
};
module.exports = ___CSS_LOADER_EXPORT___;
