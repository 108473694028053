import { render, staticRenderFns } from "./CeHeadlessSitepackageTextimage.vue?vue&type=template&id=79f55304&"
import script from "./CeHeadlessSitepackageTextimage.vue?vue&type=script&lang=js&"
export * from "./CeHeadlessSitepackageTextimage.vue?vue&type=script&lang=js&"
import style0 from "./CeHeadlessSitepackageTextimage.vue?vue&type=style&index=0&lang=postcss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThemeAtomsHeadline: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/theme/atoms/Headline.vue').default,HtmlParser: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/content/utilities/HtmlParser.vue').default,ThemeAtomsText: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/theme/atoms/Text.vue').default,ThemeAtomsButton: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/theme/atoms/Button.vue').default,CeImage: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/content/elements/CeImage.vue').default,CeMediaFile: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/content/elements/CeMediaFile.vue').default})
