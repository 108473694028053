//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import baseCe from '../mixins/baseCe'

export default {
  name: 'CeImage',
  components: {
    Swiper,
    SwiperSlide,
  },
  extends: baseCe,
  props: {
    assets: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        loop: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  methods: {
    prev() {
      this.swiper.slidePrev()
    },
    next() {
      this.swiper.slideNext()
    },
    convertToClearNumber(num) {
      return num.replace(/[^0-9&.]/g, '')
    },
    convertRemToPixels(rem) {
      return (
        parseFloat(this.convertToClearNumber(rem)) *
        (typeof document !== 'undefined'
          ? parseFloat(getComputedStyle(document.documentElement).fontSize)
          : 16)
      )
    },
  },
}
