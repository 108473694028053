//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import baseCe from '../mixins/baseCe'

export default {
  name: 'CeTextImage',
  extends: baseCe,
  props: {
    bodytext: {
      type: String,
      required: false,
      default: '',
    },
    subheader: {
      type: String,
      required: false,
      default: '',
    },
    slider: {
      type: Number,
      required: true,
      default: 0,
    },
    assets: {
      type: Array,
      required: true,
      default: () => [],
    },
    imageposition: {
      type: String,
      required: true,
    },
    headerLinks: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
